'use client'

import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'
import type React from 'react'
import { useEffect, useMemo } from 'react'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ButtonPill, px2rem, shadows, spacing } from '@fortum/elemental-ui'

import { useWidgetHeading } from '@/logged-in/components/WidgetHeading'
import { calculateStats } from '@/shared/components/SpotPrices/Graph/calculateStats'
import { SpotPricesStoreProvider, useStore } from '@/shared/components/SpotPrices/StoreProvider'
import { TabSelector } from '@/shared/components/SpotPrices/TabSelector'
import {
  TomorrowTodaySwitcher,
  settingsByMode,
} from '@/shared/components/SpotPrices/TomorrowTodaySwitcher'
import type { DataResolution, PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { useResponsiveValue } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useLocale } from '@/shared/locale'
import { trpc } from '@/shared/trpc/react'
import { getDateRange, isToday, isTomorrow } from '@/shared/utils/dates'
import { logErrorWithCause } from '@/shared/utils/error'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import { AriaLiveRegion } from './AriaLiveRegion'
import type { TopTabOptionIds } from './commons'
import { getTodayTomorrowBackFromActiveDate, mapSpotPriceSeries } from './commons'
import { ContentPanel } from './ContentPanel'
import { DateBrowser } from './DateBrowser'
import { Heading } from './Heading'
import { type SpotPriceComponentLayout, defaultValues } from './state'
import { TopTabSelector } from './TabSelector/TopTabSelector'
import { GraphStates } from '../../../features/logged-in/components/GraphStates'

type SpotPricesPureProps = {
  isError: boolean
  isLoading: boolean
  hasNoData: boolean
  hasNoTomorrowData: boolean
  showMapButton?: boolean
  showTopTab?: boolean
  priceArea: PriceAreaCode
  onRefresh: VoidFunction
  onClickMapButton: () => void
  boxProps?: CommonBoxProps
}

export const SpotPricesPure: React.FC<SpotPricesPureProps> = ({
  isError,
  isLoading,
  hasNoData,
  hasNoTomorrowData,
  showMapButton,
  showTopTab,
  priceArea,
  onRefresh,
  onClickMapButton,
  boxProps,
}) => {
  const { colors } = useTheme()
  const t = useTranslations('spotPrices')

  const activeDate = useStore((s) => s.activeDate)
  const dateResolution = useStore((s) => s.dateResolution)
  const bottomTabs = useStore((s) => s.bottomTabs)
  const activeTopTab = useStore((s) => s.topTabs.find((t) => t.active)?.id ?? 'daily')
  const activeBottomTab = bottomTabs.find((t) => t.active)?.id ?? 'graph'
  const isTodayOrTomorrow = isToday(activeDate, priceArea) || isTomorrow(activeDate, priceArea)
  const showSpotPriceAriaContent =
    isTodayOrTomorrow && !hasNoData && !hasNoTomorrowData && !isLoading && !isError
  const getPriceMode = getTodayTomorrowBackFromActiveDate(activeDate, priceArea)
  const ariaLabelPriceText = settingsByMode[getPriceMode].priceLabel

  const content = useMemo(() => {
    if (isLoading) {
      return <GraphStates state="loading" />
    }
    if (isError) {
      return <GraphStates state="error" />
    }
    if (hasNoTomorrowData) {
      return <GraphStates state="no-tomorrow-data" onRefresh={onRefresh} />
    }
    if (hasNoData) {
      return <GraphStates state="no-data" />
    }
    return <ContentPanel />
  }, [isLoading, isError, hasNoTomorrowData, hasNoData, onRefresh])

  const { WidgetHeadingBoundaryProvider, setRef } = useWidgetHeading()

  const buttonText = useResponsiveValue({
    default: priceArea,
    l: `${t('priceArea')} (${priceArea})`,
  })

  return (
    <Box
      id="spot-price-graph-container"
      display="flex"
      flex="1"
      backgroundColor={colors.background}
      flexDirection="column"
      gap={{ default: spacing.xxs, s: spacing.xs }}
      borderRadius={spacing.xxxxs}
      {...boxProps}
      boxRef={setRef}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={{ default: spacing.xxs, s: spacing.xs }}
        alignItems={{ default: 'flex-start', l: 'center' }}
      >
        <Box display="flex" flexDirection="column" flex={1}>
          <WidgetHeadingBoundaryProvider>
            <Heading />
          </WidgetHeadingBoundaryProvider>
        </Box>
        {showMapButton && (
          <ButtonPill status="secondary" onClick={onClickMapButton}>
            {buttonText}
          </ButtonPill>
        )}
      </Box>

      {showTopTab && <TopTabSelector data-testid="spot-price-top-tab-selector" />}

      <Box m={{ l: '0 auto', s: '0 auto' }}>
        <DateBrowser
          data-testid="spot-price-date-browser"
          disabled={isLoading}
          granularity={dateResolution}
        />
        {ariaLabelPriceText && (
          <AriaLiveRegion>
            {hasNoTomorrowData && content}
            {showSpotPriceAriaContent && (
              <span>
                {t('ariaLabelSpotPriceUpdate', {
                  tab: t(activeBottomTab),
                  todayOrTomorrowPrice: t(ariaLabelPriceText),
                })}
              </span>
            )}
          </AriaLiveRegion>
        )}
      </Box>
      <Box
        id={activeBottomTab}
        display="flex"
        flexDirection="column"
        gap={{ default: spacing.xxs, m: spacing.xs }}
        justifyContent="center"
        minHeight={{
          default: activeBottomTab === 'graph' ? px2rem(470) : 'auto',
          s: activeBottomTab === 'graph' ? px2rem(420) : px2rem(525),
        }}
      >
        {content}
      </Box>
      <Box
        // This is only for a11y purposes support
        id={activeTopTab}
        display="flex"
        flex="1"
        justifyContent="space-between"
        gap="inherit"
        alignItems={{ s: 'center' }}
        height={spacing.m}
      >
        <TabSelector data-testid="spot-price-tab-selector" />
        {dateResolution === 'Day' && (
          <TomorrowTodaySwitcher
            data-testid="spot-price-tomorrow-today-switcher"
            disabled={isLoading}
          />
        )}
      </Box>
    </Box>
  )
}

export const SpotPricesStorybook: React.FC<SpotPricesPureProps & { isYearlyData?: boolean }> = (
  props,
) => {
  const { isYearlyData } = props
  const data = useStore((s) => s.mappedData)
  const setData = useStore((s) => s.setMappedData)
  const activeDate = useStore((s) => s.activeDate)

  useEffect(() => {
    const updatedData = data.map((item) => {
      const newItem = { ...item }
      if (!isYearlyData) {
        newItem.time = DateTime.fromJSDate(new Date(item.time))
          .set({ year: activeDate.year, month: activeDate.month, day: activeDate.day })
          .toUTC()
          .toString()
      }
      return newItem
    })

    setData(updatedData)
  }, [activeDate, isYearlyData])

  return <SpotPricesPure {...props} />
}

type SpotPricesTrpcProps = {
  priceArea: PriceAreaCode
  onToggleMapDisplay: () => void
  showMapButton?: boolean
  showTopTab?: boolean
  boxProps?: CommonBoxProps
}

export const SpotPricesTrpc: React.FC<SpotPricesTrpcProps> = ({
  priceArea,
  onToggleMapDisplay,
  showMapButton,
  showTopTab,
  boxProps,
}) => {
  const dataResolution = useStore((s) => s.dataResolution)
  const dateResolution = useStore((s) => s.dateResolution)
  const activeDate = useStore((s) => s.activeDate)
  const [fromDate, toDate] = getDateRange(dateResolution, activeDate, priceArea, 'date')
  const setActiveDate = useStore((s) => s.setActiveDate)
  const setMappedData = useStore((s) => s.setMappedData)
  const setPriceUnit = useStore((s) => s.setPriceUnit)
  const setPriceArea = useStore((s) => s.setPriceArea)
  const topTabs = useStore((s) => s.topTabs)
  const activeTopTab = topTabs.find((t) => t.active)?.id ?? 'daily'
  const activeTopTabResolution = mapTopTabOptionToResolution(activeTopTab)

  const handleClickMapButton = () => {
    onToggleMapDisplay()
  }

  const {
    data: areasData,
    isError,
    isFetching,
    isLoading,
    refetch,
  } = trpc.shared.spotPrices.listPriceAreaSpotPrices.useQuery({
    priceArea,
    fromDate,
    toDate,
    resolution: dataResolution,
  })

  const data = areasData?.[0]

  useEffect(() => {
    if (!data) {
      return
    }
    setMappedData(mapSpotPriceSeries(data.spotPriceSeries, data.priceUnit))
    setPriceUnit(data.priceUnit)
    setPriceArea(priceArea)
  }, [data, setMappedData, setPriceUnit, setPriceArea, priceArea])

  const hasNoData = !data?.spotPriceSeries.some((item) => item.spotPrice !== null) && !isFetching
  const hasNoDataTomorrow = hasNoData && isTomorrow(activeDate, priceArea)

  useEffect(() => {
    if (activeTopTabResolution === 'MONTH') {
      const timeZone = getTimeZoneForPriceAreaCode(priceArea)
      const currentDate = DateTime.now().setZone(timeZone)
      setActiveDate(currentDate)
    }

    if (dataResolution !== activeTopTabResolution) {
      refetch().catch((error) => {
        logErrorWithCause('Error occurred while refetching data:', error)
      })
    }
  }, [
    dataResolution,
    activeTopTab,
    refetch,
    activeTopTabResolution,
    dateResolution,
    priceArea,
    setActiveDate,
  ])

  return (
    <SpotPricesPure
      isError={isError}
      isLoading={isLoading || isFetching}
      hasNoData={hasNoData}
      hasNoTomorrowData={hasNoDataTomorrow}
      onRefresh={refetch}
      onClickMapButton={handleClickMapButton}
      showMapButton={showMapButton}
      showTopTab={showTopTab}
      priceArea={priceArea}
      boxProps={{ ...boxProps }}
    />
  )
}

type SpotPricesProps = Parameters<
  typeof trpc.shared.spotPrices.listPriceAreaSpotPrices.useQuery
>[0] & {
  layout?: SpotPriceComponentLayout
  tooltip: string | undefined
}

export const SpotPricesFirstData: React.FC<SpotPricesProps> = ({
  layout,
  tooltip,
  ...queryParams
}) => {
  const { data: areasData } = trpc.shared.spotPrices.listPriceAreaSpotPrices.useQuery(queryParams)
  const locale = useLocale()
  const data = areasData?.[0]

  const mappedData = useMemo(() => {
    if (!data) {
      return
    }
    return mapSpotPriceSeries(data.spotPriceSeries, data.priceUnit)
  }, [data])

  const stats = useMemo(() => {
    if (!mappedData) {
      return
    }
    const timeZone = getTimeZoneForPriceAreaCode(queryParams.priceArea)

    const { average, ...KPIs } = calculateStats(
      mappedData,
      timeZone,
      locale,
      defaultValues.dateResolution,
    )
    return { average, KPIs }
  }, [mappedData, queryParams.priceArea, locale])

  const averagePrice = useMemo(() => {
    if (!stats || !data) {
      return
    }
    return {
      value: stats.average,
      unit: data.priceUnit,
    }
  }, [stats, data])

  return (
    <SpotPricesStoreProvider
      initialValues={{
        mappedData,
        averagePrice,
        priceArea: queryParams.priceArea,
        layout,
        tooltip,
      }}
      initialKPIs={stats?.KPIs}
    >
      <SpotPricesTrpc
        priceArea={queryParams.priceArea}
        onToggleMapDisplay={() => {}}
        boxProps={{ boxShadow: shadows.m, padding: { default: spacing.xxs, s: spacing.xs } }}
      />
    </SpotPricesStoreProvider>
  )
}

const mapTopTabOptionToResolution = (tabOption: TopTabOptionIds): DataResolution => {
  switch (tabOption) {
    case 'daily':
      return 'DAY'
    case 'yearly':
      return 'MONTH'
    default:
      return 'HOUR'
  }
}
