import { DateTime } from 'luxon'
import { createFormatter } from 'next-intl'
import { getTranslations } from 'next-intl/server'
import { match } from 'ts-pattern'

import type { CustomerAddressInfo } from '@/shared/graphql/queries/queryTypes'
import type {
  ContractBasicDataFragment,
  InitializationContractFragment,
  InitializationDataQuery,
  InvoiceDetailsContractFragment,
  PriceAreaCode,
  PriceType,
} from '@/shared/graphql/schema/commonBackend/graphql'
import { safeUnionFlatten } from '@/shared/utils/flattenUnion'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import type { SummaryEntry } from '../components/Contracts/ContractCost/lib'
import type { GranularityOption } from '../components/Energy/commons'

export type TimeSeriesDataType = 'Consumption' | 'Spotprice' | 'Production'

export type LocalizedAndTimezoneDate = DateTime & { _: number }
export const setTZAndLocale = (date: DateTime | string, timeZone: string, locale: string) =>
  (typeof date === 'string' ? DateTime.fromISO(date) : date)
    .setZone(timeZone)
    .setLocale(locale) as LocalizedAndTimezoneDate

export const formatLocaleDate = (date: LocalizedAndTimezoneDate, formatString: string) =>
  date.toFormat(formatString)

export const convertToCsv = <T extends Record<string, string | number | undefined | null>>(
  type: TimeSeriesDataType,
  data: T[],
  translatedStrings: TranslatedEnergyStrings | TranslatedSpotPriceStrings,
  totalValues?: Array<string | undefined | null>,
  averagePrice?: string,
) => {
  // Extract headers (column names)
  const headers = Object.keys(data[0])

  // Create rows for CSV
  const csvRows = data.map((row) =>
    headers.map((fieldName) => JSON.stringify(row[fieldName])).join(','),
  )

  // Combine headers and rows and return CSV string
  switch (type) {
    case 'Consumption':
    case 'Production':
      const totalBlank = totalValues
        ? new Array(headers.length - (totalValues.length + 1)).fill('')
        : []
      const totalRows = totalValues
        ? [
            `${'total' in translatedStrings ? translatedStrings['total'] : 'Total'}`,
            ...totalValues.map((value) => `"${value}"`),
            ...totalBlank,
          ]
        : []
      return [headers.join(','), ...csvRows, totalRows].join('\r\n')
    case 'Spotprice':
      const averagePriceRows = averagePrice
        ? [
            `${'average' in translatedStrings ? translatedStrings['average'] : 'Average'}`,
            `"${averagePrice}"`,
          ]
        : []
      return [headers.join(','), ...csvRows, averagePriceRows].join('\r\n')
    default:
      return ''
  }
}

export const replacer = (_key: string, value: string | number) => {
  // If the value is a string and contains a comma, newline or double-quote,
  // escape double quotes
  if (
    typeof value === 'string' &&
    (value.includes(',') || value.includes('\n') || value.includes('"'))
  ) {
    return `"${value.replaceAll('"', '""')}"`
  }
  return value
}

type CreateDownloadFileNameParams = {
  type: TimeSeriesDataType
  activeGranularity?: GranularityOption
  date: DateTime | null
  locale: string
  priceArea: PriceAreaCode
}

export const createDownloadFileName = ({
  type,
  activeGranularity,
  date,
  locale,
  priceArea,
}: CreateDownloadFileNameParams): string => {
  const timeZone = getTimeZoneForPriceAreaCode(priceArea)
  const { dateTime } = createFormatter({ locale, timeZone })
  const formattedDate = date ? dateTime(date.toJSDate()) : ''
  const month = date ? `${date.month < 10 ? '0' : ''}${date.month}` : ''
  const year = date?.year ?? ''
  if (type !== 'Spotprice') {
    return match(activeGranularity)
      .returnType<string>()
      .with('Day', () => `${type}_${formattedDate}.csv`)
      .with('Month', () => `${type}_${year}-${month}.csv`)
      .otherwise(() => `${type}_${year}.csv`)
  }
  return `${type}_${formattedDate}.csv`
}

export const downloadCSV = (
  type: TimeSeriesDataType,
  csvString: string,
  date: DateTime | null,
  priceArea: PriceAreaCode,
  locale: string,
  activeGranularity?: GranularityOption,
) => {
  const utfString = '\uFEFF' + csvString
  const blob = new Blob([utfString], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = createDownloadFileName({ type, activeGranularity, date, locale, priceArea })
  link.click()
  link.remove()
}

export const formatLocaleDecimalNumber = (locale: string, value?: number | null) =>
  isNotNullOrUndefined(value)
    ? value.toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      })
    : ''

export const capitalizeLocale = <T extends string | null>(value: T, locale: string) =>
  value ? `${value.toLocaleUpperCase(locale).charAt(0)}${value.slice(1)}` : value

export const splitMonthAndYear = (value: string) => value?.split(' ').join('<br>')

/**
 * NOTE: The token might be used in the server side rendering flow in the coming few seconds, so require
 * 30 sec expiry time instead of zero in order to avoid harder to inform unexpected access errors at requests.
 */
export const sessionExpiredOrExpiringIn30Sec = (expiryString?: string) => {
  if (!expiryString) {
    return true
  }

  const expiredDate = DateTime.fromISO(expiryString)

  return (
    !expiredDate.isValid || DateTime.now().plus({ seconds: 30 }).diff(expiredDate).milliseconds > 0
  )
}

export const getConfirmedTermsDate = (
  initializationData?: InitializationDataQuery,
): string | null => {
  return (
    initializationData?.customer.properties?.find(
      (property) => property?.id === 'termsAndConditions.web',
    )?.value ?? null
  )
}
export const isActiveContract = (
  contract:
    | InitializationContractFragment
    | ContractBasicDataFragment
    | InvoiceDetailsContractFragment,
): boolean =>
  contract.contractStatus.statusName === 'ACTIVE' || contract.contractStatus.statusName === 'ENDING'
export const isFutureContract = (
  contract: InitializationContractFragment | ContractBasicDataFragment,
): boolean => contract.contractStatus.statusName === 'STARTING'
export const isPowerContract = (
  contract:
    | InitializationContractFragment
    | ContractBasicDataFragment
    | InvoiceDetailsContractFragment,
): boolean => contract.contractType.typeName === 'ELECTRICITY'

export const formatCustomerId = (customerId?: string): string =>
  customerId ? `${customerId.slice(0, 4)} ${customerId.slice(4)}` : ''

export const constructFullAddress = (customerAddress?: CustomerAddressInfo) => {
  if (!customerAddress) {
    return ''
  }

  const flat = safeUnionFlatten(customerAddress)

  const { streetName, houseNumber, houseLetter, residence } = flat
  return streetName
    ? `${streetName} ${[houseNumber, houseLetter, residence].filter((x) => x != null).join(' ')}`.trim()
    : ''
}

type FormattedCurrencyParts = {
  value: string
  currency: string
}

export const formatTimeSpan = (date: LocalizedAndTimezoneDate) => {
  const dateWithoutMinutes = date.set({ minute: 0 })
  return `${dateWithoutMinutes.toFormat('HH.mm')} - ${dateWithoutMinutes
    .plus({ hours: 1 })
    .toFormat('HH.mm')}`
}

export const formatIntlLongDate = (date: string) => {
  const [day, month, year] = date.split(' ')
  return `${day} ${month.slice(0, 3)} ${year}`
}

export const formatCurrencyToParts = (
  value: number,
  locale: string,
  currency?: string,
): FormattedCurrencyParts => {
  const currencyParts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).formatToParts(value)

  return {
    value: currencyParts
      .reduce((acc, current) => `${acc}${current.type !== 'currency' ? current.value : ''}`, '')
      .trim(),
    currency: currencyParts.find((part) => part.type === 'currency')?.value ?? '',
  }
}

export const shouldShowSpotPrice = (priceType?: PriceType | null): boolean =>
  priceType === 'SPOT' || priceType === 'HYBRID' || priceType === 'WINTERPROOF'

export const colorOpacity = (color: string, opacity: number) =>
  `${color}${Math.floor(255 * opacity)
    .toString(16)
    .padStart(2, '0')}`

export const calculatePrice = (entry: SummaryEntry, withDiscount = false): number => {
  let price =
    entry.basePrice +
    entry.parts.reduce((acc, part) => {
      // TODO: Remove after 2024.44.0 released, ?? part.profileCost?.pricing?.price
      return acc + (part.consumptionImpact?.pricing?.price ?? part.profileCost?.pricing?.price ?? 0)
    }, 0)

  if (withDiscount) {
    if (entry.discount == null) {
      return price
    }
    price += entry.discount ?? 0
  }
  return price
}

export type TranslatedEnergyStrings = {
  consumption?: string
  production?: string
  revenue?: string
  cost?: string
  temperature?: string
  electricityPrice: string
  hour: string
  day: string
  month: string
  total: string
}

export type TranslatedSpotPriceStrings = {
  price: string
  average: string
}

export const getTranslatedStrings = async (
  type: TimeSeriesDataType,
  locale: string,
): Promise<TranslatedEnergyStrings | TranslatedSpotPriceStrings> => {
  const t = await getTranslations({ locale })
  const sharedStrings = {
    hour: t('consumption.hour'),
    day: t('consumption.day'),
    month: t('consumption.month'),
    total: t('consumption.total'),
    electricityPrice: t('consumption.electricityPrice'),
  }
  switch (type) {
    case 'Consumption':
      return {
        consumption: t('consumption.consumption'),
        cost: t('consumption.cost'),
        temperature: t('consumption.temperature'),
        ...sharedStrings,
      }
    case 'Production':
      return {
        production: t('production.production'),
        revenue: t('production.revenue'),
        ...sharedStrings,
      }
    case 'Spotprice':
      return {
        price: t('spotPrices.price'),
        average: t('spotPrices.average'),
      }
  }
}

export const replaceHyphenWithMinus = (value: string) => value.replace('−', '-')
